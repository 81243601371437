.intercom-article-content {
  @apply oneinch-text-regular-16;

  p {
    min-height: 1.53em;
  }

  hr {
    display: block;
    height: 3px;
    border-radius: 3px;
    border: none;
    margin: 1em 0;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply oneinch-text-heading-h3 mb-4;
  }

  ul {
    @apply ml-4 mt-4 list-disc;
  }

  pre {
    white-space: pre-wrap;
  }
}
