tui-tabs {
  --tui-base-03: theme("colors.day.border.border-main");

  [data-mode="onDark"] & {
    --tui-base-03: theme("colors.night.border.border-main") !important;
  }

  [tuitab] {
    --tui-tab-margin: 2rem !important;

    color: theme("colors.day.text-icons.gray-500") !important;

    [data-mode="onDark"] & {
      color: theme("colors.night.text-icons.gray-500") !important;
    }

    &._active {
      color: theme("colors.day.text-icons.main") !important;

      [data-mode="onDark"] & {
        color: theme("colors.night.text-icons.main") !important;
      }
    }

    &:hover {
      box-shadow: none !important;
    }

    &:not(._active):hover {
      color: theme("colors.day.text-icons.gray-400") !important;

      [data-mode="onDark"] & {
        color: theme("colors.night.text-icons.gray-400") !important;
      }
    }
  }

  tui-underline {
    --tui-text-02: theme("colors.day.static.primary.blue") !important;
    --tui-text-01-night: theme("colors.night.static.primary.blue") !important;
  }
}
