@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@angular/cdk/overlay-prebuilt.css";
@import "@taiga-ui/core/styles/taiga-ui-local.scss";
@import "./tabs.scss";
@import "./toggle.scss";

/* You can add global styles to this file, and also import other style files */

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  src: url(/assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  src: url(/assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

/* latin-ext */
@font-face {
  font-family: "Roboto Mono";
  font-weight: normal;
  font-style: normal;
  src: url(/assets/fonts/Roboto_Mono/static/RobotoMono-Regular.ttf) format("truetype");
}
/* latin */
@font-face {
  font-family: "Roboto Mono";
  font-weight: normal;
  font-style: normal;
  src: url(/assets/fonts/Roboto_Mono/static/RobotoMono-Regular.ttf) format("truetype");
}

* {
  font-family: "Roboto", serif;
}
html,
body {
  height: 100%;
  overflow: auto;
}

body {
  background-color: #f7f9ff;
  --tui-base-03: theme("colors.day.border.border-main");
  &[data-mode="onDark"],
  &[data-mode="onDark"][data-tui-theme] {
    background-color: black;
    --tui-base-01: #000;
    --tui-base-03: theme("colors.night.border.border-main");
  }

  :focus-visible {
    outline: none !important;
  }
}

.tui-skeleton:after {
  --tui-clear-hover: #ecf0f9;
  &[data-mode="onDark"] {
    --tui-clear-hover: #6c86ad;
  }
}

a {
  @apply text-day-static-brand;
  @apply whitespace-nowrap;

  [data-mode="onDark"] & {
    @apply text-night-static-brand;
  }
}

tui-hosted-dropdown div span.t-content {
  justify-content: space-between !important;
}

[tuiWrapper] {
  &._focused._focused:after {
    border-width: 1px;
    @apply border-day-static-brand;

    [data-mode="onDark"] & {
      @apply border-night-static-brand;
    }
  }
}

tui-select {
  tui-hosted-dropdown [tuiWrapper] {
    @include wrapper-hover() {
      background: theme("colors.day.bg.bg-2ry") !important;
    }
    [data-mode="onDark"] & {
      @include wrapper-hover() {
        background: theme("colors.night.bg.bg-2ry") !important;
      }
    }
  }
}

tui-combo-box,
tui-combo-box[appearence="brand-primary"] {
  tui-primitive-textfield [tuiWrapper] {
    --tui-clear-inverse: theme("colors.day.bg.input-main");
    --tui-clear-inverse-hover: theme("colors.day.bg.input-main");
    [data-mode="onDark"] & {
      --tui-clear-inverse: theme("colors.night.bg.input-main");
      --tui-clear-inverse-hover: theme("colors.night.bg.input-main");
    }
    --tui-text-01: theme("colors.day.text-icons.main");
    --tui-text-01-night: theme("colors.night.text-icons.main");
    --tui-text-02: theme("colors.day.text-icons.gray-400");
    --tui-text-02-night: theme("colors.night.text-icons.gray-400");

    --tui-error-fill: theme("colors.day.text-icons.error");
    --tui-error-fill-night: theme("colors.night.text-icons.error");
    --tui-disabled-opacity: 1;

    label {
      --tui-text-01: theme("colors.day.text-icons.gray-400");
      --tui-text-01-night: theme("colors.night.text-icons.gray-400");
    }

    [tuiTextfield] {
      color: theme("colors.day.text-icons.main");
      color: theme("colors.night.text-icons.main");
    }

    [appearance="icon"][tuiWrapper] {
      --tui-text-01: theme("colors.day.text-icons.gray-500");
      --tui-text-01-night: theme("colors.night.text-icons.gray-500");
    }

    @include wrapper-focus() {
      background: theme("colors.day.bg.input-main") !important;

      [data-mode="onDark"] & {
        background: theme("colors.night.bg.input-main") !important;
      }
    }

    @include wrapper-disabled() {
      label {
        --tui-text-02: theme("colors.day.text-icons.gray-500");
        --tui-text-02-night: theme("colors.night.text-icons.gray-500");
      }

      [appearance="icon"][tuiWrapper] {
        --tui-text-01: theme("colors.day.text-icons.gray-500");
        --tui-text-01-night: theme("colors.night.text-icons.gray-500");
      }

      &:after {
        border-width: 1px;
        @apply border-day-border-border-main;
        [data-mode="onDark"] & {
          @apply border-night-border-border-main;
        }
      }
    }

    &._invalid._invalid:after {
      border-width: 1px;
    }

    &:after {
      border-width: 0;
    }
  }

  &[appearance="brand-secondary"] {
    tui-primitive-textfield [tuiWrapper] {
      --tui-clear-inverse: theme("colors.day.bg.bg-3ry");
      --tui-clear-inverse-hover: theme("colors.day.bg.bg-3ry");
      [data-mode="onDark"] & {
        --tui-clear-inverse: theme("colors.night.bg.bg-3ry");
        --tui-clear-inverse-hover: theme("colors.night.bg.bg-3ry");
      }
    }
  }
}

tui-input-number [tuiWrapper] {
  background: theme("colors.day.bg.bg-2ry") !important;

  [data-mode="onDark"] & {
    background: theme("colors.night.bg.bg-2ry") !important;
  }
}

tui-data-list {
  --tui-text-01: theme("colors.day.text-icons.gray-500");
  --tui-text-01-night: theme("colors.night.text-icons.gray-500");

  @apply bg-day-bg-input-main;
  [data-mode="onDark"] & {
    @apply bg-night-bg-input-main;
  }

  button {
    // @include wrapper-hover() {
    //   background: theme("colors.day.bg.bg-2ry");
    //   [data-mode="onDark"] & {
    //     background: theme("colors.night.bg.bg-2ry");
    //   }
    // }
  }
}

tui-accordion {
  .t-wrapper {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .t-header {
    padding: 1rem !important;
    border: none !important;
    cursor: pointer;
    &:hover {
      @apply hover:bg-day-bg-bg-2ry dark:hover:bg-night-bg-bg-2ry;
    }
  }

  .t-content {
    padding: 0 0 0 1rem !important;
  }

  .t-header_open {
    background: none !important;
  }
}

tui-hints-host {
  @apply oneinch-text-regular-13;
  --tui-primary: theme("colors.day.bg.tab-selected");
  --tui-primary-text: theme("colors.day.text-icons.main");
  --tui-text-01: theme("colors.day.text-icons.main");
  --tui-elevation-01: theme("colors.day.bg.tab-selected");

  [data-mode="onDark"] & {
    @apply oneinch-text-regular-13;
    --tui-primary: theme("colors.night.bg.tab-selected");
    --tui-primary-text: theme("colors.night.text-icons.main");
    --tui-text-01: theme("colors.night.text-icons.main");
    --tui-elevation-01: theme("colors.night.bg.tab-selected");
  }
}

tui-hint {
  box-shadow: theme("boxShadow.day-shadow-xxl-form");
  [data-mode="onDark"] & {
    box-shadow: none;
  }
}

.t-root-content {
  display: flex;
  flex-direction: column;
}

[tuiWrapper][data-appearance="outline"] {
  --tui-link: theme("colors.day.static.black");
  --tui-base-04: theme("colors.day.static.black");
  --tui-base-05: theme("colors.day.static.black");
  background: white !important;
  color: white;
}

[tuiWrapper][data-appearance="flat"] {
  --tui-link: theme("colors.day.static.black");
  @apply oneinch-text-regular-16;
}

tui-axes * {
  border-style: none;
}

tui-select {
  --tui-clear: theme("colors.day.bg.input-main");

  [data-mode="onDark"] & {
    --tui-clear: theme("colors.night.bg.input-main");
  }
}

[tuiWrapper][data-appearance="textfield"] {
  --tui-clear: theme("colors.day.bg.input-main");

  [data-mode="onDark"] & {
    --tui-clear-inverse: theme("colors.night.bg.input-main");
  }
}

tui-dropdown {
  border: none !important;
  --tui-base-04: transparent;

  [data-mode="onDark"] & {
    box-shadow: 0px 9px 42px 0px rgba(0, 0, 0, 0.5) !important;
  }

  box-shadow: 0px 9px 42px 0px rgba(55, 73, 105, 0.3) !important;

  & tui-data-list button {
    --tui-duration: 0s;
    & {
      --tui-link: theme("colors.day.static.black");
      @apply oneinch-text-regular-16;
      width: 100% !important;
      @include wrapper-hover() {
        background: theme("colors.day.bg.bg-2ry");
        --tui-text-01: theme("colors.day.text-icons.main");
      }
      @include wrapper-focus() {
        background: theme("colors.day.bg.bg-2ry");
        --tui-text-01: theme("colors.day.text-icons.main");
      }
      [data-mode="onDark"] & {
        @include wrapper-hover() {
          background: theme("colors.night.bg.bg-2ry");
          --tui-text-01: theme("colors.night.text-icons.main");
        }
        @include wrapper-focus() {
          background: theme("colors.night.bg.bg-2ry");
          --tui-text-01: theme("colors.night.text-icons.main");
        }
      }

      &:focus {
        background: theme("colors.day.bg.bg-2ry");
        [data-mode="onDark"] & {
          background: theme("colors.night.bg.bg-2ry");
        }
      }
    }
  }
}

[data-mode="onDark"] {
  [tuiWrapper][data-appearance="error-button"] {
    --tui-duration: 0s;
    background: #f0483229;
    color: #f04832;

    @include wrapper-hover() {
      background: #c13d5466;
    }

    @include wrapper-disabled() {
      background: #263042;
      color: #44556f;
    }
  }

  [tuiWrapper][data-appearance="outline"] {
    background: #141924 !important;
    --tui-link-night-hover: #fbfbfb;
    --tui-duration: 0s;
  }
}

[tuiWrapper][data-appearance="success-button"] {
  --tui-duration: 0s;
  background: theme("colors.day.text-icons.approve");
  color: theme("colors.day.static.white");
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  [data-mode="onDark"] & {
    background: theme("colors.night.text-icons.approve");
    color: theme("colors.night.static.white");
  }
}

// This goes into global, unencapsulated styles
[tuiWrapper][data-appearance="login"] {
  --tui-duration: 0s;
  @apply oneinch-text-medium-16 tablet:w-[25rem] text-day-static-black bg-day-static-white w-80;
  @apply bg-day-bg-input-main;

  @include wrapper-hover() {
    @apply border-day-border-border-main text-day-text-icons-gray-500 border;
  }

  [data-mode="onDark"] & {
    background: #141924;
    color: #fbfbfb;

    @include wrapper-hover() {
      @apply border-night-border-border-main text-night-text-icons-gray-500 border;
    }
  }
}

[tuiButton] [tuiWrapper] {
  @apply oneinch-text-medium-13 #{!important};
}

[tuiWrapper][data-appearance="brand-primary"] {
  --tui-duration: 0s;
  --tui-disabled-opacity: 1;
  --tui-padding: 1rem;

  @apply bg-day-static-brand text-day-static-white;

  @include wrapper-hover() {
    background: #5ab0ff;
  }

  @include wrapper-disabled() {
    @apply bg-day-states-button-disabled text-day-text-icons-gray-400;
  }

  [data-mode="onDark"] & {
    @apply bg-night-static-brand text-night-static-white;

    @include wrapper-hover() {
      background: #5ab0ff;
    }

    @include wrapper-disabled() {
      @apply bg-night-states-button-disabled text-night-text-icons-gray-400;
    }
  }
}

[tuiWrapper][data-appearance="brand-secondary"] {
  --tui-duration: 0s;
  --tui-disabled-opacity: 1;
  @apply oneinch-text-button-16 text-day-static-primary-blue bg-day-bg-bg-info-2ry;
  @include wrapper-hover() {
    @apply bg-day-bg-bg-info-2ry-hover;
  }

  @include wrapper-disabled() {
    background: theme("colors.day.states.button-disabled");
    color: theme("colors.day.text-icons.gray-400");
  }

  [data-mode="onDark"] & {
    --tui-duration: 0s;
    @apply text-night-static-primary-blue bg-night-bg-bg-info-2ry;

    @include wrapper-hover() {
      @apply bg-night-bg-bg-info-2ry-hover;
    }

    @include wrapper-disabled() {
      background: theme("colors.night.states.button-disabled");
      color: theme("colors.night.text-icons.gray-400");
    }
  }
}

[tuiWrapper][data-appearance="error-button"] {
  --tui-duration: 0s;
  background: #f0483229;
  color: #f04832;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  @include wrapper-hover() {
    background: #c13d5466;
  }

  @include wrapper-disabled() {
    background: #263042;
    color: #44556f;
  }
}

[tuiWrapper][data-appearance="action-error-button"] {
  --tui-duration: 0s;
  background: #e3402a;
  color: theme("colors.day.static.white");
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  @include wrapper-hover() {
    background: #e75d4a;
  }

  @include wrapper-disabled() {
    background: #263042;
    color: #44556f;
  }
}

[tuiWrapper][data-appearance="gradient-button"] {
  --tui-duration: 0s;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  background: linear-gradient(269.27deg, #2f8af5 0.52%, #0361ce 48.96%, #0fbee4 100%);
  color: theme("colors.day.static.white");
  &:hover {
    opacity: 0.75;
  }
}

[tuiWrapper][data-appearance="button-info"] {
  --tui-duration: 0s;
  background: #2f8af529;
  color: #2f8af5;

  @include wrapper-hover() {
    background: #2f8af552;
  }
}

[tuiWrapper][data-appearance="button-link"] {
  --tui-padding: 1rem;
  --tui-duration: 0s;
  background: transparent;
  color: theme("colors.day.static.primary.blue");

  @include wrapper-hover() {
    background: theme("colors.day.bg.bg-info-2ry");
  }

  @include wrapper-disabled() {
    color: theme("colors.day.text-icons.gray-400");
  }

  [data-mode="onDark"] & {
    color: theme("colors.night.static.primary.blue");

    @include wrapper-hover() {
      background: theme("colors.night.bg.bg-info-2ry");
    }

    @include wrapper-disabled() {
      color: theme("colors.night.text-icons.gray-400");
    }
  }
}

.mobile-bottom-sheet-panel-class {
  position: absolute !important;
  @apply left-0 right-0;
}

tui-dialog {
  --tui-elevation-01: theme("colors.day.bg.bg-main");
  [data-mode="onDark"] & {
    --tui-elevation-01: theme("colors.night.bg.bg-main");
  }

  > .t-content {
    padding: 0 !important;
  }
}
